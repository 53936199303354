
import { Companies } from "@/api";
import { Company, ContractType, PrivateCompany } from "@/api/types";
import ChangePasswordModal from "@/components/common/ChangePasswordModal.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import Delegates from "@/components/companies/Delegates.vue";
import RepresentativesCreate from "@/components/companies/RepresentativesCreate.vue";
import { PhoneCodes } from "@/mixins/countryCodes.mixin";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Delegates,
    PageTitle,
    ValidationProvider,
    ValidationObserver,
    RepresentativesCreate,
    ChangePasswordModal
  },
  mixins: [PhoneCodes]
})
export default class CompaniesEditPage extends Vue {
  tab = 0;
  company: Company = {};
  privateCompany: PrivateCompany = {
    name: "",
    surname: "",
    cellPhone: undefined,
    passport: "",
    birthday: moment()
      .add(1, "days")
      .format("YYYY-MM-DD"),
    genderId: undefined,
    textNote: "",
    email: "",
    contractTypeId: "",
    exist: 0
  };
  contractTypes: Array<ContractType> = [];
  tableHeader = [
    {
      header: "Delegates",
      icon: "mdi-account-multiple"
    }
  ];
  genders = [
    {
      name: "Male",
      id: 1
    },
    {
      name: "Female",
      id: 2
    }
  ];
  terms = [
    {
      name: "COD",
      id: 2
    },
    {
      name: "EFT",
      id: 3
    }
  ];
  headers = [
    { text: "Name", value: "name", align: "left" },
    { text: "ID", value: "delegatesId", align: "center" },
    { text: "Phone number", value: "phoneNumber", align: "center" },
    { text: "Address", value: "address", align: "center" },
    { text: "Last event", value: "lastEvent", align: "center" },
    { text: "Date", value: "date", align: "center" }
  ];
  date = new Date().toISOString().substr(0, 10);
  menu = false;
  menu2 = false;
  menu3 = false;
  menuPrivate = false;
  get buttonsArray() {
    const btns = [
      {
        text: "Back",
        icon: "mdi-chevron-left",
        color: "white",
        action: "back"
      },
      {
        text: "Delete",
        icon: "mdi-delete",
        color: "gray",
        action: "delete"
      }
    ];
    if (this.tab === 0 || this.companyType === "Private") {
      btns.push({
        text: "Save",
        icon: "mdi-pencil-box-outline",
        color: "dark",
        action: "save"
      });
    }
    if (this.companyType === "Private") {
      btns.splice(1, 0, {
        text: "Change Password",
        icon: "mdi-lock-reset",
        color: "gray",
        action: "changePassword"
      });
    }
    return btns;
  }
  get headersTitle() {
    return [{ icon: "mdi-domain", header: this.company.name }];
  }
  get companyId() {
    return this.$route.params.id;
  }
  get companyType() {
    return this.$route.params.companyType;
  }
  async created() {
    try {
      [this.company, this.contractTypes] = await Promise.all([
        Companies.info(this.companyId),
        Companies.contractTypes()
      ]);
      if (this.company.type === "2") {
        const privateInfo = await Companies.infoPrivate(this.companyId);
        this.privateCompany.name = this.company.name?.split(" ")[0];
        this.privateCompany.surname = this.company.name?.split(" ")[1];
        this.privateCompany.cellPhone = this.company.representativePhone;
        this.privateCompany.passport = privateInfo.passport;
        this.privateCompany.birthday = privateInfo.birthday
          ? moment(privateInfo.birthday, "DD-MM-YYYY").format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
        this.privateCompany.genderId = privateInfo.genderId;
        this.privateCompany.textNote = this.company.textNote;
        this.privateCompany.email = this.company.representativeEmail;
        this.privateCompany.contractTypeId = this.company.contractTypeId;
        this.privateCompany.exist = this.company.exist;
      }
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async onDelete() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Companies.remove(this.companyId);

        if (this.$route.name === "CompaniesEdit") {
          this.$router.push({ name: "CompaniesList" });
        }
      } catch (e) {
        const err = e as any;
        await this.$error(err.errors);
      }
    }
  }
  async onBack() {
    await this.$router.push({ name: "CompaniesList" });
  }
  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }
  async save() {
    try {
      if (this.company.type === "1") {
        delete this.company.business;
        delete this.company.companyType;
        delete this.company.representativeAvailabilityFrom;
        delete this.company.representativeAvailabilityTo;
        this.company.exist = Number(this.company.exist);
        await Companies.update(this.companyId, this.company);
      } else {
        this.privateCompany.exist = Number(this.privateCompany.exist);
        await Companies.updatePrivate(this.companyId, this.privateCompany);
      }
      await this.$success(
        "<strong>Success!</strong> Company has been updated!"
      );

      if (this.$route.name === "CompaniesEdit") {
        this.$router.push({ name: "CompaniesList" });
      }
    } catch (e) {
      const err = e as any;
      if (this.company.type === "1") {
        (this.$refs.companyForm as Vue & {
          setErrors: (errors: any) => void;
        }).setErrors(err.errors);
        await this.$error(err.errors);
      } else if (this.company.type === "2") {
        (this.$refs.privateForm as Vue & {
          setErrors: (errors: any) => void;
        }).setErrors(err.errors);
        await this.$error(err.errors);
      }
    }
  }
  get getFormattedBirthDay() {
    return moment(this.privateCompany.birthday).format("DD/MM/YYYY");
  }

  changePassword() {
    this.$modal.show("change-paswword-modal");
  }
}
